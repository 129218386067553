/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "images/gnuradio_00.jpg",
    alt: "GNU Radio",
    title: "GNU Radio"
  })), "\n", React.createElement(_components.p, null, "La tendencia hoy en día es el paso de la electrónica analógica a electrónia digital y de ésta a software y esta tendencia se nota especialmente en el tema de la ", React.createElement(_components.a, {
    href: "http://www.neoteo.com/sdr-radio-definida-por-software"
  }, "Radio Definida por Software"), " (SDR), idealmente la SDR pretende digitalizar directamente las ondas de radio y trabajar con esos datos obtenido directamente por software, para una comunicación bidireccional se tendría además que realizar el proceso inverso, inyectar datos por la antena mediante un convertidor digital/analógico."), "\n", React.createElement(_components.p, null, "Pero dejando a un lado los detalles técnicos la Radio Definida por Software tiene muchas aplicaciónes que aún no se han puesto en práctica, la mas importante bajo mi punto de vista es la ", React.createElement(_components.a, {
    href: "http://www.eliax.com/?post_id=5506"
  }, "Radio Cognitiva"), ", mediante esta técnica es posible aprovechar de una forma mucho más eficiente el espectro de radiofrecuencia, puesto que la radio para comunicarse salta a la banda de frecuencia que encuentra libre."), "\n", React.createElement(_components.p, null, "Aunque la Radio Definida por Software aún es una tecnología cara gracias a ", React.createElement(_components.a, {
    href: "http://gnuradio.org/redmine/projects/gnuradio/wiki"
  }, "GNU Radio"), " no es necesario que nos preocupemos por la parte del software, puesto que tenemos a nuestra disposición un conjunto de herramientas libres para el procesado de la señal."), "\n", React.createElement(_components.p, null, "Lo más caro para su implantación es el hardware que la hace posible. Junto con GNU Radio funciona USRP, desarrollado por ", React.createElement(_components.a, {
    href: "http://www.ettus.com/"
  }, "Ettus Research"), ", pero aún es excesivamente caro para una implantación masiva. Por otra parte se ha descubierto una forma mas sencilla y barata de acceder a esta tecnología, a cambio de acceder a un ancho de banda mas pequeño y de forma unidireccional, ", React.createElement(_components.a, {
    href: "http://www.neoteo.com/radio-definida-por-software-al-alcance-de-todos"
  }, "gracias al hackeo del chip Realtek RTL2832"), ". De esta forma es posible introducir a muchos interesados en este mundo."), "\n", React.createElement(_components.p, null, "El problema para la implantación de estas tecnologías es lo limitado que está el uso libre del espectro radioeléctrico, los estados que aún funcionan sobre la lógica de la era industrial no permiten libertad en el uso del espectro radioeléctrico y solo unas estrechas bandas las puede utilizar la gente común, en contra de estas prácticas ya existen iniciativas como la ", React.createElement(_components.a, {
    href: "http://www.openspectrum.info/"
  }, "Open Spectrum"), " que buscan poner al alcance de todos el uso responsable de las ondas de radio."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
